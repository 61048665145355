import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import CompanyCards from 'components/Indoor_Cameras/IN-6001_HD/Warranty/CompanyCards';
import WeeSymbol from 'components/Indoor_Cameras/IN-6001_HD/Warranty/faq01Image';
import NavButtons from 'components/Indoor_Cameras/IN-6001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-6001 HD Manual Warranty & Disposal",
  "path": "/Indoor_Cameras/IN-6001_HD/Warranty/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-6001 HD is the first INSTAR camera with the new 720p chipset.",
  "image": "./P_SearchThumb_IN-6001HD_Warranty.png",
  "social": "/images/Search/P_SearchThumb_IN-6001HD_Warranty.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-6001 HD Manual Warranty & Disposal' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6001 HD Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-6001HD_Warranty.png' twitter='/images/Search/P_SearchThumb_IN-6001HD_Warranty.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6001_HD/Garantie/' locationFR='/fr/Indoor_Cameras/IN-6001_HD/Warranty/' crumbLabel="Warranty" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-6001-hd-warranty--disposal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-6001-hd-warranty--disposal",
        "aria-label": "in 6001 hd warranty  disposal permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-6001 HD Warranty & Disposal`}</h2>
    <h3 {...{
      "id": "2-year-limited-warranty",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-year-limited-warranty",
        "aria-label": "2 year limited warranty permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2-year Limited Warranty`}</h3>
    <p>{`INSTAR offers a 2-year warranty on its products. During this period, you can send in your camera for a free repair. This excludes cameras installed with custom-made or 3rd party Firmware and WebUIs which have not been approved by INSTAR. The warranty is only for hardware parts of the camera. If you are contacting our Service Team, please keep your model and serial number ready. If you want to send your camera for repair, please use the RMA form that came with your camera. You can also download it from our website instar.de or contact the INSTAR Service Team:`}</p>
    <CompanyCards mdxType="CompanyCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "general-public-license",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#general-public-license",
        "aria-label": "general public license permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General Public License`}</h3>
    <p><strong parentName="p">{`This product contains software programs subject to the GPL free software license`}</strong></p>
    <p>{`This product contains software that was developed by third parties and/or software subject to the GNU General Public License (GPL) and/or the GNU Lesser General Public License (LGPL). We will send you the source code of these programs on request. The GPL and/or LGPL code used and offered in this product is EXCLUSIVE OF ANY GUARANTEE WHATSOEVER and is subject to the copyright of one or several authors. For further details, please refer to the GPL and/or LGPL code of this product and to the terms of usage of GPL and LGPL.`}</p>
    <p>{`You can read the complete license text at `}<OutboundLink href="http://www.gnu.org/licenses/gpl-2.0.html" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink"><a parentName="p" {...{
          "href": "http://www.gnu.org"
        }}>{`www.gnu.org`}</a></OutboundLink>{`.`}</p>
    <h3 {...{
      "id": "disposal-of-old-electrical-appliances",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#disposal-of-old-electrical-appliances",
        "aria-label": "disposal of old electrical appliances permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Disposal of old electrical appliances`}</h3>
    <p><strong parentName="p">{`Waste Electrical and Electronic Equipment`}</strong></p>
    <p>{`The European Directive 2002/96/EC on Waste Electrical and Electronic Equipment (WEEE), requires that old household electrical appliances must not be disposed of in the normal unsorted municipal waste stream. Old appliances must be collected separately in order to optimize the recovery and recycling of the materials they contain and reduce the impact on human health and the environment.`}</p>
    <WeeSymbol mdxType="WeeSymbol" />
    <p>{`The crossed out “wheeled bin” symbols on the product reminds you of your obligation, that when you dispose of the appliance it must be separately collected. Consumers should contact their local authority or retailer for information concerning the correct disposal of their old appliance.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      